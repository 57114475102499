import { Link } from '@chakra-ui/react';
import React from 'react';
import { palette, SUPPORT_EMAIL } from '../core/constants';

export const SupportEmailLink = () => {
  return (
    <Link
      color={palette.themePrimary}
      href={`mailto:${SUPPORT_EMAIL}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {SUPPORT_EMAIL}
    </Link>
  );
};
